import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../../components/data-display/text";
import MenuListItem from "../../components/menu-popper/menu-list-item";
import { RootState } from "../../redux/index.reducers";
import { FiUser, FiShield, FiCreditCard, FiBook, GrStar } from "react-icons/all";
import PersonalInfoTab from "../../components/my-account-components/personal-info-tab/personal-info-tab";
import BanksAndCardsTab from "../../components/credit-card-handler/bank-and-cards-tab";
import { Link } from "gatsby";
import SecurityTab from "../../components/my-account-components/security-tab/security-tab";
import Reviews from "../../components/my-account-components/reviews/reviews";
import PaymentsHistory from "../../components/my-account-components/payment-history/payment-history";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { useLocation, Router,useMatch } from '@reach/router';
import { useSelector } from "react-redux";
import { PrivateRoute } from "../../components/app-routes/app-routes";
import SEO from "../../components/seo/seo";
const useStyles = makeStyles((theme) => ({
    drawer: {
        padding: "24px 24px",
        width: "360px",
        background: "white",
        borderRadius: "6px",
        boxShadow: "0 1px 2px rgb(0,0,0,0.1)"
    },
    reviewsDrawer: {
        background: 'none',
        border: 'none',
        boxShadow: "none"
    }
}));
const MyAccount = () => {
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const { userData } = useSelector((state: RootState) => state.userData);
    const accountMatch = useMatch("/account/:page")
    return (
    
        <div style={{zIndex:0}}>
            <SEO description={"Sydetasker helps everyone get more done."} title={"Sydetasker - Post. Hire. Done."} pathname={"/account"} />
                (
                    <Container fixed maxWidth={"lg"} style={{ marginTop: xsDown ? "80px" : "100px", display: "flex", flexDirection: xsDown ? "column" : "row", minHeight: "calc(100vh - 100px)", }}>
                        <div className={classes.drawer} style={{ height: 'fit-content', width: xsDown ? "auto" : '', }}>
                            <Text variant={"h6"} bold >Account Settings</Text>
                            <div style={{ height: "24px" }} />
                            <Link to={"/account/"}>
                                <MenuListItem large icon={<FiUser size={16} strokeWidth={3} />} active={accountMatch && accountMatch[""] === ""}>
                                    Personal Info
                                </MenuListItem>
                            </Link>
                            <div style={{ height: "8px" }} />
                            <Link to={"/account/reviews"}>
                                <MenuListItem large icon={<GrStar size={16} strokeWidth={3} />} active={accountMatch && accountMatch["page"] === "reviews"}>
                                    Reviews
                                </MenuListItem>
                            </Link>
                            <div style={{ height: "8px" }} />
                            <Link to={"/account/security"}>
                                <MenuListItem large icon={<FiShield size={16} strokeWidth={3} />} active={accountMatch && accountMatch["page"] === "security"}>
                                    Security & login
                                </MenuListItem>
                            </Link>
                            <div style={{ margin: "12px 0", height: "1px", width: "100%", background: "rgb(240,240,240)" }} />
                            <Link to={"/account/banks-and-cards"}>
                                <MenuListItem large icon={<FiCreditCard size={16} strokeWidth={3} />} active={accountMatch && accountMatch["page"] === "banks-and-cards"} >
                                    Banks & Cards
                                </MenuListItem>
                            </Link>
                            <div style={{ height: "8px" }} />
                            {/* <Link to={"/account/payment-history"}>
                                <MenuListItem large icon={<FiBook size={16} strokeWidth={3} />} active={accountMatch && accountMatch["page"] === "payment-history"} >
                                    Payment History
                                </MenuListItem>
                            </Link> */}
                        </div>
                        <div className={classes.drawer} style={{ flex: 1, marginLeft: xsDown ? "" : "24px", height: "fit-content", minHeight: "20vh", width: xsDown ? "auto" : '', }}>
                            {
                               accountMatch ?
                                    (() => {
                                        switch (accountMatch["page"]) {
                                            case "":
                                                return <PersonalInfoTab />;
                                            case "banks-and-cards":
                                                return <BanksAndCardsTab />;
                                            case "security":
                                                return <SecurityTab />;
                                            case "reviews":
                                                return <Reviews />;
                                            // case "payment-history":
                                            //     return <PaymentsHistory />;
                                        }
                                    })()
                                    :
                                    <PersonalInfoTab />
                            }
                        </div>
                    </Container>
                    </div>
    )
}


const App = () => {
    return (
        <div className="content">
                <Router>
                    <PrivateRoute path={"/account/*"} component={MyAccount} />
                </Router>
        </div>
    )
}

export default App;


