import React from "react";
import FormErrorText from "../../data-display/form-error-text";
import Form from "../../forms/form-wrapper";
import FormInput from "../../forms/form-input";
import MiniButton from "../../buttons/mini-button";
import { auth } from "../../../utils/firebase.utils";
import firebase from "firebase";

const PasswordForm = (props) => {
  const savePassword = (values: Record<string, any>) => {
    return new Promise((resolve, reject) => {
      if (values.new !== values.reTypeNew) {
        return reject("password doesn't match");
      }
      const reg = new RegExp(/(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/);
      const numberRegx = new RegExp(/\d/);
      const upperCaseRegx = new RegExp(/^(?=.*[A-Z])/);
      const symbolRegx = new RegExp(/(?=.*[@$!%*#?&+-\/])/);
      if (values.new.length < 8) {
        return reject("Must be 8+ characters.");
      } else if (!(values.new.match(numberRegx))) {
        return reject("Must contain at-least on number");
      } else if (!(values.new.match(upperCaseRegx))) {
        return reject("Must contain at-least on uppercase");
      } else if (!(values.new.match(symbolRegx))) {
        return reject("Must contain at-least on symbol");
      } else if (values.new && values.new.match(reg)) {
        let user = auth?.currentUser;
        let credential = firebase.auth.EmailAuthProvider.credential(user.email, values.current);

        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            user
              .updatePassword(values.new)
              .then(() => {
                resolve("Success");
                props.onClose();
              })
              .catch((err) => {
                switch (err.code) {
                  case "auth/weak-password":
                    return reject({ new: "password is not strong enough." });
                  case "auth/requires-recent-login":
                    return reject("Please sign in again.");
                  default:
                    return reject("Something went wrong on our side.");
                }
              });
          })
          .catch((err) => {
            switch (err.code) {
              case "auth/user-not-found":
                return reject("Couldn't find your account.");
              case "auth/wrong-password":
                return reject({ current: "That's an incorrect password. Try again." });
              case "auth/invalid-email":
                return reject("Please enter a valid Email Address.");
              default:
                return reject("Something went wrong on our side.");
            }
          });
      } else {
        return reject("Password must contain 8+ characters, a symbol & a number.");
      }
    });
  };

  return (
    <Form onSubmit={savePassword} style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          position: "relative",
          right: "0px",
          top: "2px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <MiniButton style={{ position: "fixed", transform: "translate(0%, -55px)" }}>Save</MiniButton>
      </div>
      <FormInput label={"Current"} name={"current"} type={"password"} required />
      <FormInput label={"New"} name={"new"} type={"password"} required />
      <FormInput label={"Re-type new"} name={"reTypeNew"} type={"password"} required />
      <FormErrorText />
    </Form>
  );
};

export default PasswordForm;
