import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Grid from "@material-ui/core/Grid";
import ListItem from "../../list-handlers/list-tem";
import ListContainer from "../../list-handlers/list-container";
import Avatar from "../../avatar/avatar";
import Text from "../../data-display/text";
import DialogWrapper from "../../dialogs/dialog-wrapper";
import PasswordForm from "../edit-account-forms/password-form";
import EmailForm from "../edit-account-forms/email-form";

const SecurityTab = () => {

    const { userData } = useSelector((state: RootState) => state.userData);

    const [openDialog, setOpenDialog] = React.useState('');

    const handleOpen = (type) => {
        setOpenDialog(type);
    };
    const handleClose = () => {
        setOpenDialog('');
    };

    return <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
            <Text
                style={{ marginLeft: "6px", color: "grey", marginTop: "8px" }}
                variant="overline"
                component="p"
            >
                Password
            </Text>
            <ListContainer>
                <ListItem
                    last={true}
                    label={'change password'}
                    value={null}
                    fallback={'Modify your password.'}
                    onClick={()=> handleOpen('password')}
                />
            </ListContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <Text
                style={{ marginLeft: "6px", color: "grey", marginTop: "8px" }}
                variant="overline"
                component="p"
            >
                Email
            </Text>
            <ListContainer>
                <ListItem
                    last={true}
                    label={'change email'}
                    value={userData?.email}
                    fallback={'--'}
                    onClick={() => handleOpen('email')}
                />
            </ListContainer>
        </Grid>

        <DialogWrapper root title={"Change Password"} onClose={handleClose} open={openDialog==="password"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }}/>
                <PasswordForm onClose={handleClose}/>
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Change Email"} onClose={handleClose} open={openDialog==="email"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }}/>
                <EmailForm onClose={handleClose}/>
            </div>
        </DialogWrapper>
    </Grid>
}

export default SecurityTab;