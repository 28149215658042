import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/index.reducers";
import Grid from "@material-ui/core/Grid";
import ListItem from "../../list-handlers/list-tem";
import ListContainer from "../../list-handlers/list-container";
import Avatar from "../../avatar/avatar";
import Text from "../../data-display/text";
import DialogWrapper from "../../dialogs/dialog-wrapper";
import NameForm from "../edit-account-forms/name-form";
import PhoneNumberForm from "../edit-account-forms/phone-number-form";
import BirthDateForm from "../edit-account-forms/birth-date-form";
import AboutMeForm from "../edit-account-forms/about-me-form";
import TaglineForm from "../edit-account-forms/tagline-form";
import EducationForm from "../edit-account-forms/education-form";
import SkillsForm from "../edit-account-forms/skills-form";
import PictureOptions from "../edit-account-forms/picutre-options-form";
import CommunicationEmailForm from "../edit-account-forms/communication-email-form";
// import AttachmentHandler from "../attachment-handler";
import PortfolioForm from "../potfolio-form";
import ResumeForm from "../edit-account-forms/resume-form";
import { FaPlusCircle, FiXCircle } from "react-icons/all";

const PersonalInfoTab = () => {

    const { userData } = useSelector((state: RootState) => state.userData);
    const [openAttacmentsDialog, setOpenAttacmentsDialog] = React.useState<boolean>(false);
    const [updatedUser, setUpdatedUser] = React.useState(userData ? userData : null);
    const [openDialog, setOpenDialog] = React.useState('');

    const handleOpen = (type) => {
        setOpenDialog(type);
    };

    const handleClose = () => {
        setOpenDialog('');
    };


    return <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={6}>
            <Text
                style={{ marginLeft: "6px", color: "grey", marginTop: "8px" }}
                variant="overline"
                component="p"
            >
                PUBLIC INFORMATION
            </Text>
            <ListContainer>
                <ListItem
                    label={'profile picture'}
                    value={<Avatar profilePicture={userData?.profilePicture} name={userData?.name} userId={userData?.slug_title} size={32} />}
                    fallback={'A photo helps personalize your profile.'}
                    onClick={() => handleOpen('profilePicture')}
                />
                <ListItem
                    label={'full name'}
                    value={userData?.name}
                    fallback={'--'}
                    onClick={() => handleOpen('fullName')}
                />
                <ListItem
                    label={'tagline'}
                    value={userData?.tagLine}
                    fallback={'--'}
                    onClick={() => handleOpen('tagLine')}
                />
                <ListItem
                    label={'about me'}
                    value={userData?.about}
                    fallback={
                        'Tell us more so members can know who you are and what you do!'
                    }
                    onClick={() => handleOpen('about')}
                />
                <ListItem
                    label={'portfolio'}
                    value={""}
                    fallback={
                        'Photos of your work helps to show what you can do for others'
                    }
                    onClick={() => handleOpen('attachments')}
                />
            </ListContainer>
            <div style={{ height: "12px" }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <Text
                style={{ marginLeft: "6px", color: "grey", marginTop: "8px" }}
                variant="overline"
                component="p"
            >
                PRIVATE INFORMATION
            </Text>
            <ListContainer>
                <ListItem
                    label={'mobile number'}
                    value={userData?.phoneNumber}
                    fallback={'--'}
                    onClick={() => handleOpen('phoneNumber')}
                />
                <ListItem
                    label={'birth date'}
                    value={userData?.birthDate?.formattedDate}
                    fallback={'--'}
                    onClick={() => handleOpen('birthDate')}
                />
                <ListItem
                    label={'communication email'}
                    value={userData?.communicationEmail}
                    fallback={'--'}
                    onClick={() => handleOpen('communication')}
                />
                <ListItem
                    label={'resume'}
                    value={""}
                    fallback={'upload your resume'}
                    last
                    onClick={() => handleOpen('resume')}
                />
            </ListContainer>
            <div style={{ height: "24px" }} />
            <Text
                style={{ marginLeft: "6px", color: "grey" }}
                variant="overline"
                component="p"
            >
                ADDITIONAL INFORMATION
            </Text>
            <ListContainer>
                <ListItem
                    label="education"
                    value={userData?.education}
                    fallback={'--'}
                    onClick={() => handleOpen('education')}
                />
                <ListItem
                    label="skills"
                    value={userData?.skills}
                    fallback={'--'}
                    last
                    onClick={() => handleOpen('skills')}
                />
            </ListContainer>
            <div style={{ height: "12px" }} />
        </Grid>




        <DialogWrapper root title={"Edit Profile Picture"} onClose={handleClose} open={openDialog === "profilePicture"} height={"200px"}>
            <div style={{ height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ height: "2px" }} />
                <PictureOptions onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Full Name"} onClose={handleClose} open={openDialog === "fullName"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <NameForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Porfolio"} onClose={handleClose} open={openDialog === "attachments"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <PortfolioForm user={updatedUser} updateUser={(updated) => { setUpdatedUser(updated); }} onContinue={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Resume"} onClose={handleClose} open={openDialog === "resume"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <ResumeForm  user={updatedUser} updateUser={(updated) => { setUpdatedUser(updated); }} onContinue={handleClose} />
            </div>
        </DialogWrapper>

        <DialogWrapper root title={"Edit Tagline"} onClose={handleClose} open={openDialog === "tagLine"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <TaglineForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit About Me"} onClose={handleClose} open={openDialog === "about"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <AboutMeForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Mobile Number"} onClose={handleClose} open={openDialog === "phoneNumber"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <PhoneNumberForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Birth Date"} onClose={handleClose} open={openDialog === "birthDate"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <BirthDateForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Education"} onClose={handleClose} open={openDialog === "education"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <EducationForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Skills"} onClose={handleClose} open={openDialog === "skills"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <SkillsForm onClose={handleClose} />
            </div>
        </DialogWrapper>
        <DialogWrapper root title={"Edit Communication Email"} onClose={handleClose} open={openDialog === "communication"}>
            <div style={{ height: "500px" }}>
                <div style={{ height: "2px" }} />
                <CommunicationEmailForm onClose={handleClose} />
            </div>
        </DialogWrapper>
    </Grid>
}

export default PersonalInfoTab;